import { h, Fragment as F } from 'preact'
import { useState } from 'preact/hooks';

import { convertToCamelCase } from '../tools.js'
import { getFeatureSite } from './helper.js'
import { TabContainer } from './tabContainer.js'

const defaultTab = [
  { 'beauty': 'Beauty & Well-Being' },
  { 'fashion': 'Fashion' },
  { 'food': 'Food' },
  { 'accessories': 'Gifts' }
]

export function FeatureSite({needPagination, maxPerPage = 9, isHome=false}) {
  const { sites, isLoading } = getFeatureSite()
  const [ currentPage, updateCurrentPage ] = useState(1)

  const color = 'text-default'
  const defaultColor = isHome ? 'text-grey4' : 'text-grey4'
  if (isLoading)
    return null
  return (
    <F>
      <div class="">
        <nav class="-mb-0.5 flex justify-center space-x-6  border-b-2 border-grey3" aria-label="Tabs" role="tablist">
          {
            defaultTab.map( (tab, index) => (
              <button type="button" onClick={ ((index) => () => updateCurrentPage(index))(index + 1) } class={ `${index == 0 ? 'active': ''} 
                hs-tab-active:font-semibold ${defaultColor} hs-tab-active:text-default 
                hs-tab-active:border-b-2 hs-tab-active:border-brandColor
                pt-4 pb-2 px-4 inline-flex 
                items-center gap-2 border-b-[3px] border-transparent sm:text-base whitespace-nowrap`} 
              id={`horizontal-alignment-item-${index + 1}`}
              data-hs-tab={`#horizontal-alignment-${index + 1}`} aria-controls={`horizontal-alignment-${index + 1}`} role="tab">
              {Object.values(tab)[0]}
              </button>
            ))
          }
        </nav>
      </div>
      <div class="mt-7">
        {
          defaultTab.map( (tab, index) => (
            <TabContainer sites={sites?.category[Object.keys(tab)[0]]} index={index + 1} key={Object.keys(tab)[0]} needPagination={needPagination} maxPerPage={maxPerPage}/>
          ))
        }

      </div>
    </F>
  )
}

