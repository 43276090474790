import useSWR from 'swr';
import { host, fetcher } from '../tools.js'


export function getFeatureSite() {
  const url = `${host ?? '' }/registry-office/api/featured`
  const { data, error } = useSWR(url, fetcher, {
    loadingTimeout: 5000
  })

  const result = data?.data.reduce((init, data) => {
    // group by country
    if (init.country[data.registrant_country]){
      init.country[data.registrant_country].push(data)
    } else {
      init.country[data.registrant_country] = [data]
    }
    // group by category
    if (init.category[data.category]){
      init.category[data.category].push(data)
    } else {
      init.category[data.category] = [data]
    }
    return init
  },{country: {}, category: {}})

  return {
    sites: result,
    isLoading: !error && !data,
    isError: error
  }
}
