import { h } from 'preact'
/** @jsx h */
import { useState, useEffect } from 'preact/hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import { Pagination } from '../pagination.js'



export function TabContainer({sites = [], index, needPagination, maxPerPage}) {
  const [ currentPageNo, updatePageNo ] = useState(1);
  const [ filteredSites, updateFilteredSites ] = useState([]);
  const [ haveMore, updateStatus ] = useState(false)

  useEffect(() => {
    const firstDomain = (currentPageNo - 1) * maxPerPage;
    updateFilteredSites(sites?.slice(0, firstDomain+maxPerPage));
  }, [ sites, currentPageNo ]);

  useEffect(() => {
    updateStatus((currentPageNo * maxPerPage) < sites.length )
  }, [ filteredSites ])

  const nextPage = (e) => {
    e.preventDefault();
    updatePageNo(page => page + 1 )
  }


  function openInNewTab(url) {
    const _url = url.startsWith('http') ? url : `http://${url}`
    window.open(_url, '_blank').focus();
  }
  return (
    <div id={`horizontal-alignment-${index}`} role="tabpanel" aria-labelledby={`horizontal-alignment-item-${index}`} class={`${index === 1 ? '': 'hidden'} `}>
      <div class="flex flex-wrap justify-start">{
        filteredSites.map( site => (
          <div class="group flex basis-full md:basis-1/2 lg:basis-1/3 grow p-3 relative" key={site.domain_name}>
            <a class="flex flex-col w-full items-center" href="#" onClick={() => openInNewTab(site.domain_name)}>
            <LazyLoadImage
              alt={site.domain_name + "Screenshot"}
              src={site.screenshot_url} // use normal <img> attributes as props
              height={200}
              class="w-full object-cover object-top min-w-[300px] h-[250px] sm:h-[300px] md:h-[200px]"/>
              <div class="p-4 md:p-5 text-center bg-brandColor4 group-hover:bg-brandColor rounded-tl-[30px] w-[80%] relative bottom-[30px] h-[70px] flex justify-center items-center">
                <h3 class=" text-white text-[16px]">
                  {site.domain_name}
                </h3>
              </div>
            </a>
          </div>
        ))
      }
        <div class="flex basis-full md:basis-1/2 lg:basis-1/3 grow p-0 content-none invisible"></div>
        <div class="flex basis-full md:basis-1/2 lg:basis-1/3 grow p-0 content-none invisible"></div>
        <div class="flex basis-full md:basis-1/2 lg:basis-1/3 grow p-0 content-none invisible"></div>
      </div>
        {/* { needPagination && <Pagination currentPageNo={currentPageNo} onChangePage={updatePageNo} totalNoOfPage={Math.ceil(sites.length / maxPerPage)}/> } */}
        {
          needPagination && haveMore && <div class="flex justify-center mt-4">
              <a class="mb-12 bg-brandColor inline-flex items-center  pl-7 pr-3 py-3 text-white rounded-[100px]" onClick={nextPage} target="_blank" >
                Load more
                <svg width="18" height="18" viewBox="0 0 18 18"  fill="#fff" class="ml-7 mt-3 mr-3 rotate-90" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.16971 0.372264C1.67336 -0.124087 0.868614 -0.124088 0.372263 0.372263C-0.124088 0.868614 -0.124087 1.67336 0.372264 2.16971L7.20255 9L0.372263 15.8303C-0.124088 16.3266 -0.124088 17.1314 0.372263 17.6277C0.868614 18.1241 1.67336 18.1241 2.16971 17.6277L9.89872 9.89872C10.3951 9.40237 10.3951 8.59763 9.89872 8.10128L2.16971 0.372264Z" />
                </svg>
              </a>
            </div>
        }
    </div>
  )
}
