// import Swiper from 'swiper';
  // import Swiper styles
import { h, render, Fragment as F } from 'preact'
/** @jsx h */
import { FeatureSite } from './../featured/featureSite.js'

const el = document.getElementById('featured-site');
render((
  <FeatureSite maxPerPage={6} isHome/>
), el);

// new Swiper ('.swiper', {
//   // slidesPerView: 'auto',
//   spaceBetween: 10,
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   pagination: {
//     el: '.swiper-pagination'
//   },
//   breakpoints: {
//     // when window width is >= 320px
//     320: {
//       slidesPerView: 1,
//       spaceBetween: 10
//     },
//     // when window width is >= 480px
//     640: {
//       slidesPerView: 2,
//       spaceBetween: 10
//     },
//     // // when window width is >= 640px
//     1024: {
//       slidesPerView: 3,
//       spaceBetween: 0
//     }
//   }

// });


